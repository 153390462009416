'use client'

export const Polyfill = () => {
  // Polyfill for Promise.withResolvers
  // Use to resolve react-pdf error
  // https://github.com/wojtekmaj/react-pdf/issues/1855#issuecomment-2332822693
  if (typeof Promise.withResolvers !== 'function') {
    Promise.withResolvers = function <T>() {
      let resolve!: (value: T | PromiseLike<T>) => void
      let reject!: (reason?: any) => void
      const promise = new Promise<T>((res, rej) => {
        resolve = res
        reject = rej
      })
      return { promise, resolve, reject }
    }
  }
  return null
}
