'use client'

import { SessionProvider } from 'next-auth/react'

import { NavigationProgressBar } from './NavigationProgressBar'

type AppProps = {
  children: React.ReactNode
}

export const PublicAppProviders: React.FC<AppProps> = ({ children }) => {
  return (
    <SessionProvider>
      {children}
      <NavigationProgressBar />
    </SessionProvider>
  )
}
